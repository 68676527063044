import { appInfoUtils } from 'core/utils/app-info';
import { debugLogger } from 'core/debug-logger';

/**
 * The "Unexpected token '<'" error is a known error that we typically see after new deployments (see CC-9202).
 * Ignoring this error is temporary until a more permanent solution can be implemented (see CC-9364).
 *
 * TODO: Remove code to ignore this error once permanent solution is implemented.
 */
const ERROR_MESSAGES_TO_IGNORE = ["Unexpected token '<'"];

const isNewRelicLoaded = () => Boolean(window.newrelic && window.newrelic.info.licenseKey);

/**
 * Ignores an interaction so that it is not saved or sent to New Relic
 * @param err
 */
const ignoreError = err => isNewRelicLoaded() && window.newrelic.interaction().ignore();

/**
 * New Relic Browser Agent API. Identifies a browser error without disrupting the application.
 *
 * @param err Required. Provides a meaningful error message that you can use when analyzing data on
 * New Relic's JS Errors page.
 * @param componentStack componentStack from ErrorBoundary
 * @param location
 * @param attrs Optional. An object containing name/value pairs representing custom attributes which can be used to
 * group and label error objects.
 */
const logError = (err, componentStack, location, attrs) => {
  if (!isNewRelicLoaded()) return;

  const attributes = {
    ...(componentStack && { componentStack }),
    ...(location && { location }),
    ...(attrs && { ...attrs }),
  };

  window.newrelic.noticeError(err, attributes);
};

/**
 * New Relic Browser Agent API. Adds a user-defined attribute name and value to subsequent events on the page.
 *
 * @param {String} name Required. Name of the attribute.
 * @param {(String|Number)} value Required. Value of the attribute.
 */
const setCustomAttribute = (name, value) => isNewRelicLoaded() && window.newrelic.setCustomAttribute(name, value);

/**
 * Sets Custom Page View name using the setCurrentRouteName from the Browser API
 *
 * @param pathname the path name from React Router Location.pathname | window.location.pathname
 * @param search the search value from React Router Location.search | window.location.search
 */
const setRouteName = (pathname, search) => {
  let location = pathname;

  if (search) {
    location += `${search}`;
  }

  if (isNewRelicLoaded()) window.newrelic.setCurrentRouteName(`${window.location.host}${location}`);
};

const shouldIgnoreError = err =>
  !!(err && err.message && ERROR_MESSAGES_TO_IGNORE.some(errorMessage => errorMessage === err.message));

/**
 * Initializes the setErrorHandler method from the Browser API.
 * This method allows us to selectively ignore known errors that the browser agent captures.
 */
const initSetErrorHandler = () =>
  isNewRelicLoaded() &&
  window.newrelic.setErrorHandler(err => {
    if (shouldIgnoreError(err)) {
      return true;
    }
    return false;
  });

/**
 * Inits the New Relic script by tagging all events with the App Version
 * This will help us determine if a bug may have been introduced between versions
 * Make sure to run this init as soon as possible, before all other scripts so that we have accurate information in NR
 */
const init = () => {
  if (isNewRelicLoaded()) {
    window.newrelic.addRelease('CarCode React App', appInfoUtils.getAppVersion());

    initSetErrorHandler();
  }
};

/**
 * Logs an error to New Relic using the LogsAPI
 * @param error {Error} Error object
 * @param info {Object} Object containing componentStack
 * @returns {Promise<void>}
 */
const logsApi = async (error, info) => {
  if (!isNewRelicLoaded()) return;

  if (appInfoUtils.isProd()) {
    const url = 'https://log-api.newrelic.com/log/v1';
    const stack =
      info && info.componentStack ? info.componentStack.toString : error.stack || 'Unable to log error stack';

    await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Api-Key': '015a81a0d5e85c821d8567a62c01c99fFFFFNRAL',
      },
      body: JSON.stringify({
        app_name: 'carcode-call-center-app-logs',
        level: 'ERROR',
        message: error.message,
        component_stack: stack,
        referer: window.location.href,
        timestamp: Date.now(),
      }),
    }).catch(e => {
      console.error('Error logging to New Relic Service', e); // eslint-disable-line
      debugLogger.log(e);
    });
  }
};

export default {
  init,
  isNewRelicLoaded,
  logError,
  logsApi,
  ignoreError,
  setCustomAttribute,
  setRouteName,
  shouldIgnoreError,
};
