import { MEDIA_QUERY_KEY } from 'core/constants/media-query';

import { googleAnalyticsService } from 'core/tracking/google-analytics';
import { CATEGORIES } from 'core/constants/tracking';
import { mediaQueryService } from 'core/media-query';
import { COOKIE_KEYS } from 'core/constants/cookie';
import { cookieService } from 'core/cookie';
import { debugLogger } from 'core/debug-logger';

/**
 * Checks to see if a feature is supported in the browser
 * @param feature a feature from PWA_FEATURES found in `core/constants/pwa-features`
 * @returns {boolean}
 */
const isSupported = feature => feature in navigator;

const isNotificationsSupported = () => 'Notification' in window;

/**
 * Check to see if Notification feature is supported in the browser
 * @param feature
 * @returns {boolean}
 */
const isNotificationsFeatureSupported = feature => {
  if (!isNotificationsSupported()) {
    return false;
  }

  return feature in Notification;
};

/**
 * Most PWA features are experimental in Safari or not supported and cause issues
 * With this method we can detect Safari in the user agent in order to skip using features
 */
const isSafari = () =>
  navigator && navigator.userAgent && !navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('Safari');

const trackEvent = options => {
  googleAnalyticsService.event({
    ...options,
    category: CATEGORIES.PWA,
  });
};

const isPwaOpened = () => mediaQueryService.matchMedia(MEDIA_QUERY_KEY.PWA).matches;

const isPWAInstalled = () => Boolean(cookieService.get(COOKIE_KEYS.CARCODE_PWA_INSTALLED));

const setAppBadge = unreadCount => {
  if (!isNotificationsSupported() || isSafari()) {
    return;
  }

  if (isNotificationsFeatureSupported('requestPermission')) {
    try {
      Notification.requestPermission().then(() => {
        if (isSupported('setAppBadge')) {
          navigator.setAppBadge(unreadCount);
        }
      });
    } catch (e) {
      debugLogger.log(e);
    }
  }
};

let installPrompt = null;
window.addEventListener('beforeinstallprompt', event => {
  event.preventDefault();
  installPrompt = event;
});

const downloadOurApp = async () => {
  if (!installPrompt) {
    return;
  }

  try {
    const result = await installPrompt.prompt();
    if (result.outcome === 'accepted') {
      window.location.reload();
    }
  } catch (e) {
    debugLogger.log(e);
  }
};

const clearAppBadge = () => {
  if (!isNotificationsSupported() || isSafari()) {
    return;
  }

  if (isSupported('clearAppBadge')) {
    navigator.clearAppBadge();
  }
};

export default {
  isSupported,
  isPwaOpened,
  isPWAInstalled,
  isSafari,
  isNotificationsSupported,
  trackEvent,
  setAppBadge,
  clearAppBadge,
  downloadOurApp,
};
