import { ACTIVE_HINT_CLIENT_QUERY } from 'core/queries/buttons-bar';

import decorator from 'core/resolvers/graphql-type-decorator';

import { buttonsBarHintsService } from 'core/buttons-bar-hints';
import { debugLogger } from 'core/debug-logger';

export default (obj, { input: { inquiryId, activeHint } }, { cache }) => {
  try {
    const cachedActiveHint = cache.readQuery({
      query: ACTIVE_HINT_CLIENT_QUERY,
      variables: { inquiryId },
    });
    if (
      cachedActiveHint &&
      cachedActiveHint.activeHint &&
      cachedActiveHint.activeHint.key &&
      (!activeHint || !activeHint.key)
    ) {
      buttonsBarHintsService.saveUsedHint(inquiryId, cachedActiveHint.activeHint.key);
    }
  } catch (error) {
    debugLogger.log(error);
  }

  cache.writeQuery({
    query: ACTIVE_HINT_CLIENT_QUERY,
    variables: {
      inquiryId,
    },
    data: {
      activeHint: decorator.buttonsBarHint(activeHint || { key: null, prompt: null }),
    },
  });

  return null;
};
