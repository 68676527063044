import merge from 'lodash/merge';
import find from 'lodash/find';

import { INQUIRIES_LIST_INQUIRY_FRAGMENT, INQUIRIES_LIST_QUERY } from 'core/queries/inquiries';
import { INQUIRIES_FILTER_QUERY } from 'core/queries/inquiries-filter';
import { CATEGORY } from 'core/constants/inquiries/category';

import { inquiriesFilterService } from 'core/inquiries-filter';
import { inquiryService } from 'core/inquiry';
import { userService } from 'core/user';
import { debugLogger } from 'core/debug-logger';

export const shouldAddNewInquiry = ({ inquiry, user }) => {
  const inquiryBelongsToCurrentDealer = inquiryService.checkIfBelongsToDealer(inquiry, user.currentDealer.id);
  const inquiryMatchesAppliedFilter = inquiriesFilterService.matchFilters(inquiry);

  return inquiryBelongsToCurrentDealer && inquiryMatchesAppliedFilter;
};

export const getQueryVariablesByCategory = ({ category, loggedUser, cache }) => {
  const { inquiriesFilter: cachedFilters } = cache.readQuery({
    query: INQUIRIES_FILTER_QUERY,
  });

  const commonVariables = {
    userId: loggedUser.id,
    dealerId: loggedUser.currentDealer.id,
    dealerIds: inquiriesFilterService.getDealerIds(cachedFilters, loggedUser.id, loggedUser.currentDealer.id),
    filters: inquiriesFilterService.getFiltersParams(
      inquiriesFilterService.extractFilters(cachedFilters, loggedUser.id, loggedUser.currentDealer.id),
    ),
  };

  return merge(
    {},
    {
      filters: inquiriesFilterService.getCategoryFilters(category, loggedUser.id),
    },
    commonVariables,
  );
};

export const getQueryVariablesToUpdate = ({ inquiry, loggedUser, cache }) => {
  const categories = [];

  if (!inquiry.assigned.id) {
    categories.push(CATEGORY.UNASSIGNED);
    if (userService.isDealerUserOrAdmin(loggedUser)) {
      categories.push(CATEGORY.ALL);
    }
  } else if (inquiry.assigned.id === loggedUser.id) {
    categories.push(CATEGORY.ASSIGNED_TO_ME);
  } else if (userService.isAdmin(loggedUser)) {
    categories.push(CATEGORY.ASSIGNED_TO_OTHERS);
  }

  return categories.reduce(
    (result, category) =>
      result.concat([
        getQueryVariablesByCategory({
          category,
          loggedUser,
          cache,
        }),
      ]),
    [],
  );
};

export const updateInquiry = ({ cachedInquiry, nextInquiry, loggedUser, cache, id }) => {
  const nextCachedInquiry = merge({}, cachedInquiry, nextInquiry);

  // if inquiry was unassigned and becomes assigned
  // then remove it from unassigned list
  if (!cachedInquiry.assigned.id && nextCachedInquiry.assigned.id) {
    removeInquiryFromRelatedLists({
      inquiryId: cachedInquiry.id,
      loggedUser,
      cache,
      cacheId: id,
    });
  }

  cache.writeFragment({
    id,
    fragment: INQUIRIES_LIST_INQUIRY_FRAGMENT,
    fragmentName: 'InquiriesListInquiry',
    data: nextCachedInquiry,
  });

  return nextCachedInquiry;
};

export const addNewInquiryToRelatedLists = ({ inquiry, loggedUser, cache }) => {
  // update each list depends on inquiry
  getQueryVariablesToUpdate({ inquiry, loggedUser, cache }).forEach(listQueryVariables => {
    addInquiryToList({
      inquiry,
      loggedUser,
      cache,
      listQueryVariables,
    });
  });
};

export const removeInquiryFromRelatedLists = ({ inquiryId, loggedUser, cache, cacheId }) => {
  const cachedInquiry = cache.readFragment({
    id: cacheId,
    fragment: INQUIRIES_LIST_INQUIRY_FRAGMENT,
    fragmentName: 'InquiriesListInquiry',
  });

  // if no inquiry in cache, then do not need to remove it
  if (!cachedInquiry || !cachedInquiry.id) {
    return;
  }

  // update each list depends on inquiry
  getQueryVariablesToUpdate({ inquiry: cachedInquiry, loggedUser, cache }).forEach(listQueryVariables => {
    removeInquiryFromList({
      inquiryId,
      loggedUser,
      cache,
      cacheId,
      listQueryVariables,
    });
  });
};

export const removeInquiryFromUnassignedList = args =>
  removeInquiryFromListByCategory({
    category: CATEGORY.UNASSIGNED,
    ...args,
  });

export const removeInquiryFromAssignedToMeList = args =>
  removeInquiryFromListByCategory({
    category: CATEGORY.ASSIGNED_TO_ME,
    ...args,
  });

export const removeInquiryFromAssignedToOthersList = args =>
  removeInquiryFromListByCategory({
    category: CATEGORY.ASSIGNED_TO_OTHERS,
    ...args,
  });

export const removeInquiryFromCompletedList = args => {
  const queryVariables = getQueryVariablesByCategory({
    category: CATEGORY.COMPLETED,
    loggedUser: args.loggedUser,
    cache: args.cache,
  });
  return removeInquiryFromList({
    ...args,
    listQueryVariables: { ...queryVariables, searchType: 'COMPLETED', isCompleted: true },
  });
};

export const removeInquiryFromListByCategory = ({ category, ...args }) =>
  removeInquiryFromList({
    ...args,
    listQueryVariables: getQueryVariablesByCategory({
      category,
      loggedUser: args.loggedUser,
      cache: args.cache,
    }),
  });

export const removeInquiryFromList = ({ inquiryId, loggedUser, cache, cacheId, listQueryVariables }) => {
  const cachedInquiry = cache.readFragment({
    id: cacheId,
    fragment: INQUIRIES_LIST_INQUIRY_FRAGMENT,
    fragmentName: 'InquiriesListInquiry',
  });

  // if no inquiry in cache, then do not need to remove it
  if (!cachedInquiry || !cachedInquiry.id) {
    return;
  }

  try {
    const cachedInquiries = cache.readQuery({
      query: INQUIRIES_LIST_QUERY,
      variables: listQueryVariables,
    });

    if (!find(cachedInquiries.searchInquiries.list, { id: inquiryId })) {
      return;
    }

    const nextCachedInquiries = { ...cachedInquiries };
    nextCachedInquiries.searchInquiries.list = (nextCachedInquiries.searchInquiries.list || []).filter(
      inquiry => inquiry.id !== inquiryId,
    );
    nextCachedInquiries.searchInquiries.total -= 1;

    cache.writeQuery({
      query: INQUIRIES_LIST_QUERY,
      variables: listQueryVariables,
      data: nextCachedInquiries,
    });
  } catch (e) {
    debugLogger.log(e);
  }
};

export const addInquiryToList = ({ inquiry, loggedUser, cache, listQueryVariables }) => {
  try {
    const cachedInquiries = cache.readQuery({
      query: INQUIRIES_LIST_QUERY,
      variables: listQueryVariables,
    });

    if (find(cachedInquiries.searchInquiries.list, { id: inquiry.id })) {
      return;
    }

    const nextCachedInquiries = {
      searchInquiries: {
        ...cachedInquiries.searchInquiries,
        list: [inquiry, ...(cachedInquiries.searchInquiries.list || [])],
        total: cachedInquiries.searchInquiries.total + 1,
      },
    };

    cache.writeQuery({
      query: INQUIRIES_LIST_QUERY,
      variables: listQueryVariables,
      data: nextCachedInquiries,
    });
  } catch (e) {
    debugLogger.log(e);
  }
};
