import { cookieService } from 'core/cookie';
import { newRelicService } from 'core/new-relic';

const isDebugMode = () => {
  const debugCookie = cookieService.get('ccDebugMode');
  if (debugCookie) return true;

  const urlParams = new URLSearchParams(window.location.search);
  const isDebugging = !!urlParams.has('ccDebugMode');

  if (isDebugging) {
    cookieService.set('ccDebugMode', true);
  }
  return isDebugging;
};

const log = (message, sendToNewRelic = false) => {
  if (sendToNewRelic && message instanceof Error) {
    newRelicService.logsApi(message, {}).then(result => console.log); // eslint-disable-line
  }

  if (isDebugMode()) {
    console.log(message); // eslint-disable-line
  }
};

export default {
  isDebugMode,
  log,
};
